<template>
  <div>
    <div class="md-modal md-effect-11 md-show">
      <div class="bg-white p-3 text-center">
        <span class="modal-title f-14 f-w-700 text-muted">
          {{ modalTitle }}
        </span>
        <a href="#" @click.prevent="close" class="float-right text-dark">
          <i class="icon-close f-24" />
        </a>
      </div>
      <div class="md-content">
        <form @submit.prevent="handleInit2FARequest">
          <div class="card-body p-2">
            <div v-if="isConfirmation" class="row">
              <div class="col-md-auto">
                <div class="text-center" v-if="isConfirmed">
                  <div class="card-header">
                    <code
                      v-for="(recovery, index) in codes"
                      :key="`recovery-${index}`"
                    >
                      {{ recovery }}
                    </code>
                  </div>
                  <span class="float-right f-w-600 text-muted">
                    {{ $t("profile.copy_recovery_codes") }}
                  </span>
                </div>
                <div class="text-center" v-else>
                  <img :src="`data:image/png;base64, ${mfaData.qr_code_url}`" />
                </div>
              </div>
              <div class="col-md" v-if="!isConfirmed">
                <p class="text-muted p-2">
                  {{ $t("common.scan_mfa_1") }} (
                  <em>Google Authenticator, ...</em>),
                  {{ $t("common.scan_mfa_2") }}
                </p>
                <div class="p-3">
                  <BaseInput
                    v-model="code"
                    :errorMessage="codeErrorMessage"
                    :label="$t('common.code')"
                    :placeholder="$t('common.code_placeholder')"
                    iconClass="icon-menu-open"
                    type="text"
                    @blur="$v.code.$touch()"
                  />
                </div>
              </div>
            </div>
            <BaseInput
              v-else
              v-model="holder"
              :errorMessage="holderErrorMessage"
              :label="$t('common.holder')"
              :placeholder="$t('common.holder_placeholder')"
              iconClass="icon-menu-open"
              type="text"
              @blur="$v.holder.$touch()"
            />
          </div>
          <div class="card-footer bg-white pt-2 pb-2 text-center">
            <BaseButton
              :disabled="$v.$invalid"
              :loading="loading"
              :show-loading="true"
              buttonClass="be-btn primary lg blue"
            >
              {{
                isConfirmation
                  ? isConfirmed
                    ? $t("common.finish")
                    : $t("common.verify_and_confirm")
                  : $t("common.next")
              }}
            </BaseButton>
          </div>
        </form>
      </div>
    </div>
    <div class="md-overlay" @click.prevent="close"></div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "FormMultiFactorAuth",
  data() {
    return {
      loading: false,
      holder: null,
      code: null,
      errors: [],
      mfaData: null,
      codes: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["userEmail"]),
    isConfirmation() {
      return this.mfaData?.qr_code_url !== undefined;
    },
    isConfirmed() {
      return this.codes.length > 0;
    },
    modalTitle() {
      const trans = this.isConfirmation
        ? this.isConfirmed
          ? "confirm_subtitle"
          : "code_subtitle"
        : "holder_subtitle";

      return this.$t(`common.${trans}`);
    },
    holderErrorMessage() {
      if (this.$v.holder.$error || this.errors.holder) {
        if (!this.$v.holder.required) {
          return this.$t("common.insert_holder");
        }
        if (this.errors.holder) {
          return this.errors.holder[0];
        }
      }
      return "";
    },
    codeErrorMessage() {
      if (this.$v.code.$error || this.errors.code) {
        if (!this.$v.code.required) {
          return this.$t("common.insert_code");
        }
        if (this.errors.code) {
          return this.errors.code[0];
        }
      }
      return "";
    },
  },
  created() {
    this.holder = this.userEmail;
  },
  methods: {
    close() {
      this.$router.back();
    },
    handleInit2FARequest() {
      if (this.isConfirmed) this.close();
      else {
        this.$v.$touch();
        if (!this.$v.invalid && !this.loading) {
          this.loading = true;
          this.errors = [];
          if (this.isConfirmation) {
            this.$store
              .dispatch("auth/confirmMfaActivation", {
                code: this.code,
                token: this.mfaData.token,
              })
              .then(({ codes }) => {
                this.loading = false;
                this.codes = codes;
              })
              .catch(err => {
                if (err.errors) this.errors = err.errors;
                this.code = null;
                if (err.message) {
                  this.error = err.message;
                }
                this.loading = false;
              });
          } else {
            this.$store
              .dispatch("auth/initMfaActivation", { holder: this.holder })
              .then(data => {
                this.mfaData = data;
                this.loading = false;
              })
              .catch(err => {
                if (err.errors) this.errors = err.errors;
                if (err.message) {
                  this.error = err.message;
                }
                this.loading = false;
              });
          }
        }
      }
    },
  },
  validations() {
    return {
      holder: { required: requiredIf(this.mfaData === null) },
      code: { required: requiredIf(this.mfaData !== null) },
    };
  },
};
</script>

<style scoped></style>
